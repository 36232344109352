export function PhotosIcon() {
  return (
    <svg
      fill="none"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="gallery_thumbnail">
        <mask
          height="48"
          id="mask0_8536_93630"
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="48"
          x="0"
          y="0"
        >
          <rect fill="#E90C17" height="48" id="Bounding box" width="48" />
        </mask>
        <g mask="url(#mask0_8536_93630)">
          <path
            d="M6 38C4.9 38 3.95833 37.6083 3.175 36.825C2.39167 36.0417 2 35.1 2 34V14C2 12.9 2.39167 11.9583 3.175 11.175C3.95833 10.3917 4.9 10 6 10H26C27.1 10 28.0417 10.3917 28.825 11.175C29.6083 11.9583 30 12.9 30 14V34C30 35.1 29.6083 36.0417 28.825 36.825C28.0417 37.6083 27.1 38 26 38H6ZM36 22C35.4333 22 34.9583 21.8083 34.575 21.425C34.1917 21.0417 34 20.5667 34 20V12C34 11.4333 34.1917 10.9583 34.575 10.575C34.9583 10.1917 35.4333 10 36 10H44C44.5667 10 45.0417 10.1917 45.425 10.575C45.8083 10.9583 46 11.4333 46 12V20C46 20.5667 45.8083 21.0417 45.425 21.425C45.0417 21.8083 44.5667 22 44 22H36ZM38 18H42V14H38V18ZM6 34H26V14H6V34ZM8 30H24L18.75 23L15 28L12.25 24.35L8 30ZM36 38C35.4333 38 34.9583 37.8083 34.575 37.425C34.1917 37.0417 34 36.5667 34 36V28C34 27.4333 34.1917 26.9583 34.575 26.575C34.9583 26.1917 35.4333 26 36 26H44C44.5667 26 45.0417 26.1917 45.425 26.575C45.8083 26.9583 46 27.4333 46 28V36C46 36.5667 45.8083 37.0417 45.425 37.425C45.0417 37.8083 44.5667 38 44 38H36ZM38 34H42V30H38V34Z"
            fill="#E90C17"
            id="gallery_thumbnail_2"
          />
        </g>
      </g>
    </svg>
  );
}

'use client';

import { AIChatbotStrings } from '@/translations';
import { mdiSend } from '@mdi/js';
import Icon from '@mdi/react';
import { IconButton, ReportIssueDialog, Typography } from '@motortrend/ids';
import { nitrous } from '@motortrend/nitrous-web-sdk';
import { type ChatRequestOptions } from 'ai';

import { DataId } from '@/utils/nitrous/constants';

type FormProps = {
  input: string;
  setInput: (input: string) => void;
  isLoading: boolean;
  handleSubmit: (
    event?: {
      preventDefault?: () => void;
    },
    chatRequestOptions?: ChatRequestOptions,
  ) => void;
};

const handleReportIssue = (feedbackText?: string) => {
  nitrous.events.logChatbotReportIssue(feedbackText);
};

/**
 * Form Component
 *
 * A client component that renders a chat input form with a text input and send button.
 * This component is used as part of the AI Chatbot interface to capture and submit user messages.
 *
 */
export function Form({ handleSubmit, input, isLoading, setInput }: FormProps) {
  return (
    <div className="w-full">
      <form
        className="flex w-full flex-row items-center gap-2"
        onSubmit={handleSubmit}
      >
        <input
          className="h-[40px] w-full resize-none overflow-y-auto rounded-lg border border-solid border-neutral-6 bg-neutral-8 px-[15px] py-[5px] text-base placeholder:text-base placeholder:text-neutral-5 disabled:cursor-not-allowed dark:border-neutral-5 dark:bg-neutral-2"
          onChange={(event) => setInput(event.target.value)}
          placeholder={AIChatbotStrings.InputPlaceholder}
          value={input}
        />
        <IconButton
          aria-label="Send Message Button"
          data-id={DataId.AIChatbotSendMessageButton}
          data-parent={DataId.AIChatbotForm}
          disabled={isLoading}
          type="submit"
        >
          <Icon path={mdiSend} size="100%" />
        </IconButton>
      </form>
      <div>
        <Typography
          className="mt-2 px-2 text-neutral-4 dark:text-neutral-5"
          disableColorScheme
          variant="caption1"
        >
          {AIChatbotStrings.FeedbackMessage}&nbsp;
          <ReportIssueDialog
            action={handleReportIssue}
            data-id={DataId.AIChatbotFeedback}
            promptText={AIChatbotStrings.Text}
            title={AIChatbotStrings.FeedbackHeader}
            trigger="link"
            triggerText={AIChatbotStrings.FeedbackLink}
          />
        </Typography>
      </div>
    </div>
  );
}

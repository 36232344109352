/**
 * Markdown Component for AI-Generated Content
 *
 * This component is specifically designed to render markdown content generated by an AI model,
 * particularly for use in chatbot or conversational AI interfaces.
 *
 * Key Features:
 * 1. Customized rendering of markdown elements using MotorTrend's IDS Typography components.
 * 2. Optimized for AI-generated responses, which may include headings, paragraphs, and lists.
 * 3. Memoized for performance, preventing unnecessary re-renders when content hasn't changed.
 *
 * Usage:
 * <Markdown>{aiGeneratedMarkdownString}</Markdown>
 *
 * Note:
 * - This component assumes AI responses will be in markdown format.
 * - It's tailored for common AI response structures (headings, paragraphs, lists).
 * - The memoization helps in scenarios where the AI might stream responses or update frequently.
 */

import {
  memo,
  type HTMLAttributes,
  type LiHTMLAttributes,
  type OlHTMLAttributes,
} from 'react';
import { Link, Typography } from '@motortrend/ids';
import ReactMarkdown, { type Components } from 'react-markdown';

import { DataId } from '@/utils/nitrous/constants';

export function NonMemoizedMarkdown({ children }: { children: string }) {
  const components = {
    a: ({
      children,
      href,
      ...props
    }: HTMLAttributes<HTMLAnchorElement> & { href: string }) => (
      <Link
        data-id={DataId.AIChatbotLink}
        data-parent={DataId.AIChatbotMessage}
        href={href}
        rel="noopener noreferrer"
        target="_blank"
        {...props}
      >
        {children}
      </Link>
    ),
    h3: ({
      children,
      node,
      ...props
    }: HTMLAttributes<HTMLParagraphElement> & { node: Element }) => (
      <Typography as="span" variant="subtitle1" {...props}>
        {children}
      </Typography>
    ),
    li: ({
      children,
      node,
      ...props
    }: LiHTMLAttributes<HTMLLIElement> & { node: Element }) => (
      <li className="mt-3 grid" {...props}>
        {Array.from(node.children).findIndex(
          (child) => child.tagName === 'p',
        ) !== -1 ? (
          children
        ) : (
          <Typography as="span" variant="body3" {...props}>
            {children}
          </Typography>
        )}
      </li>
    ),
    ol: ({
      children,
      node,
      ...props
    }: OlHTMLAttributes<HTMLOListElement> & { node: Element }) => (
      <ol className="mb-3 inline-grid" {...props}>
        {children}
      </ol>
    ),
    p: ({
      children,
      node,
      ...props
    }: HTMLAttributes<HTMLParagraphElement> & { node: Element }) => (
      <Typography as="span" variant="body3" {...props}>
        {children}
      </Typography>
    ),
    ul: ({
      children,
      node,
      ...props
    }: HTMLAttributes<HTMLUListElement> & { node: Element }) => (
      <ul className="mb-3 inline-grid" {...props}>
        {children}
      </ul>
    ),
  } as unknown as Components;

  return <ReactMarkdown components={components}>{children}</ReactMarkdown>;
}

export const Markdown = memo(
  NonMemoizedMarkdown,
  (prevProps, nextProps) => prevProps.children === nextProps.children,
);

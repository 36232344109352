export function StarOutlined() {
  return (
    <svg
      className="stroke-[#141416] stroke-[1.5] dark:stroke-[#FCFCFD]"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.00606 4.03779C2.79665 3.61577 3.44372 2.95231 3.85775 2.14491C4.26987 2.95211 4.91395 3.61569 5.70136 4.0378C4.9142 4.45797 4.26999 5.11845 3.85776 5.92224C3.44363 5.11823 2.79642 4.45786 2.00606 4.03779ZM12.1508 4.78183C13.2653 8.55748 16.1499 11.5412 19.8139 12.6813C16.1506 13.8158 13.2654 16.7849 12.1508 20.5435C11.0307 16.7843 8.13138 13.8157 4.45179 12.6812C8.1321 11.5413 11.0309 8.55812 12.1508 4.78183ZM18.3825 20.0378C19.1731 19.6158 19.8202 18.9523 20.2342 18.1449C20.6463 18.9521 21.2904 19.6157 22.0778 20.0378C21.2906 20.458 20.6464 21.1185 20.2342 21.9222C19.8201 21.1182 19.1729 20.4579 18.3825 20.0378Z"
        id="Union"
      />
    </svg>
  );
}

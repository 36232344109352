export function ArticlesIcon() {
  return (
    <svg
      fill="none"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="export_notes">
        <mask
          height="48"
          id="mask0_8536_93625"
          maskUnits="userSpaceOnUse"
          style={{ maskType: 'alpha' }}
          width="48"
          x="0"
          y="0"
        >
          <rect fill="#D9D9D9" height="48" id="Bounding box" width="48" />
        </mask>
        <g mask="url(#mask0_8536_93625)">
          <path
            d="M32.4 41L38 35.4V40H40V32H32V34H36.6L31 39.6L32.4 41ZM10 42C8.9 42 7.95833 41.6083 7.175 40.825C6.39167 40.0417 6 39.1 6 38V10C6 8.9 6.39167 7.95833 7.175 7.175C7.95833 6.39167 8.9 6 10 6H38C39.1 6 40.0417 6.39167 40.825 7.175C41.6083 7.95833 42 8.9 42 10V23.4C41.3667 23.1 40.7167 22.8417 40.05 22.625C39.3833 22.4083 38.7 22.25 38 22.15V10H10V38H22.1C22.2 38.7333 22.3583 39.4333 22.575 40.1C22.7917 40.7667 23.05 41.4 23.35 42H10ZM10 38V10V22.15V22V38ZM14 34H22.15C22.25 33.3 22.4083 32.6167 22.625 31.95C22.8417 31.2833 23.0833 30.6333 23.35 30H14V34ZM14 26H26.2C27.2667 25 28.4583 24.1667 29.775 23.5C31.0917 22.8333 32.5 22.3833 34 22.15V22H14V26ZM14 18H34V14H14V18ZM36 46C33.2333 46 30.875 45.025 28.925 43.075C26.975 41.125 26 38.7667 26 36C26 33.2333 26.975 30.875 28.925 28.925C30.875 26.975 33.2333 26 36 26C38.7667 26 41.125 26.975 43.075 28.925C45.025 30.875 46 33.2333 46 36C46 38.7667 45.025 41.125 43.075 43.075C41.125 45.025 38.7667 46 36 46Z"
            fill="#E90C17"
            id="export_notes_2"
          />
        </g>
      </g>
    </svg>
  );
}

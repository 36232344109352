'use client';

import { useId } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from '@motortrend/ids';
import { nitrous, TrackChatbot } from '@motortrend/nitrous-web-sdk';
import { useChat } from 'ai/react';

import { getUserMessageAndToolName, saveMessage } from '@/utils/saveMessage';

import { Form } from './Form';
import { Header } from './Header';
import { Messages } from './Messages';

/**
 * AIChatbot Component
 *
 * A client-side React component that implements an AI-powered chatbot interface using the Vercel AI SDK.
 *
 * Key Features:
 * - Renders as a modal dialog that can be opened/closed via URL params
 * - Integrates with the Vercel AI SDK for chat functionality
 * - Persists chat messages to localStorage
 * - Tracks user interactions via Nitrous analytics
 *
 * Component Structure:
 * - Dialog wrapper with header, content area, and footer
 * - Header: Shows title and controls
 * - Content: Displays chat message history
 * - Footer: Contains message input form
 *
 * State Management:
 * - Uses useChat hook from Vercel AI SDK to manage chat state and interactions
 * - Maintains chat history in localStorage via saveMessage utility
 * - Tracks dialog open state via URL search params
 *
 * @returns {JSX.Element} The rendered chatbot dialog
 */
export function AIChatbot() {
  const id = useId();

  const router = useRouter();
  const searchParams = useSearchParams();

  const isDialogOpen = searchParams?.get('chatbot') === 'true';

  const { append, handleSubmit, input, isLoading, messages, setInput } =
    useChat({
      api: '/nwapi/chat',
      body: { id },
      id,
      initialMessages: [],
      keepLastMessageOnError: true,
      maxSteps: 3,
      onFinish: (message, options) => {
        if (message.toolInvocations?.[0]) {
          const invocation = message.toolInvocations[0];
          const { state } = invocation;
          if (state === 'result') {
            const { result } = invocation;
            saveMessage({
              content: JSON.stringify(result),
              id: message.id,
              role: message.role,
              toolName: invocation.toolName,
            });
          }
        }

        if (options.finishReason !== 'tool-calls') {
          const userMessageAndToolName = getUserMessageAndToolName();
          nitrous.events.logChatbotPrompt(
            userMessageAndToolName?.userMessage || '',
            userMessageAndToolName?.toolName || 'none',
            options.usage.totalTokens,
          );
        }

        if (
          typeof message.content === 'string' &&
          message.content.trim().length
        ) {
          saveMessage({
            content: message.content,
            id: message.id,
            role: message.role,
          });
        }
      },
    });

  return (
    <Dialog
      className="px-6"
      onClose={() => {
        router.back();
      }}
      open={isDialogOpen}
      showCloseButton={false}
    >
      <TrackChatbot opened={isDialogOpen} />
      <DialogTitle className="!m-0 flex flex-row items-center">
        <Header />
      </DialogTitle>

      <DialogContent className="!p-0 md:min-h-[20rem]">
        <Messages
          append={({ content, role }) => {
            const message = { content, id, role };
            saveMessage(message);
            append(message);
          }}
          messages={messages}
        />
      </DialogContent>

      <DialogFooter className="w-full !justify-start !gap-0 !p-0">
        <Form
          handleSubmit={(event) => {
            if (!input || input.trim() === '') {
              return;
            }
            saveMessage({
              content: input,
              id,
              role: 'user',
            });
            handleSubmit(event, {});
          }}
          input={input}
          isLoading={isLoading}
          setInput={setInput}
        />
      </DialogFooter>
    </Dialog>
  );
}

import { LocalStorageKey } from '@/constants';
import { Message } from 'ai';

type PersistedMessage = Pick<Message, 'content' | 'id' | 'role'> & {
  toolName?: string;
};

/**
 * Saves a message to local storage.
 *
 * This function takes a message object and adds it to the existing messages
 * stored in localStorage under the key 'ai-chatbot-messages'. If no messages exist,
 * it initializes a new array.
 *
 * @param {Object} message - The message to be saved.
 * @param {string} message.content - The content of the message.
 * @param {string} message.id - The unique identifier of the message.
 * @param {string} message.role - The role of the message sender (e.g., 'user', 'assistant').
 * @param {string} message.toolName - Optional. The name of the tool used to generate the message.
 */
export function saveMessage(message: PersistedMessage) {
  const messages = JSON.parse(
    localStorage.getItem(LocalStorageKey.AIChatbotMessages) || '[]',
  );
  try {
    localStorage.setItem(
      LocalStorageKey.AIChatbotMessages,
      JSON.stringify([...messages, message]),
    );
  } catch (error) {
    console.error('Error saving message to localStorage:', error);
  }
}

/**
 * Gets the most recent user prompt and tool name (if the chatbot used a tool to
 * generate its response) from local storage
 */
export function getUserMessageAndToolName() {
  const messages = JSON.parse(
    localStorage.getItem(LocalStorageKey.AIChatbotMessages) || '[]',
  ) as PersistedMessage[];
  try {
    return {
      userMessage: messages
        .slice()
        .reverse()
        .find((m) => m.role === 'user')?.content,
      toolName: messages[messages.length - 1].toolName,
    };
  } catch (error) {
    console.error('Error retrieving user message from localStorage:', error);
  }
}

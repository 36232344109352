export function AIChatbotIcon() {
  return (
    <svg
      fill="none"
      height="40"
      viewBox="0 0 32 32"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="#E90C17" height="32" rx="16" width="32" />
      <path
        d="M9.54 18.59C8.83333 19.29 7.97333 19.64 6.96 19.64C5.94667 19.64 5.08667 19.29 4.38 18.59C3.68 17.8833 3.33 17.02 3.33 16C3.33 14.98 3.68 14.12 4.38 13.42C5.08667 12.7133 5.94667 12.36 6.96 12.36C7.97333 12.36 8.83333 12.7133 9.54 13.42C10.2467 14.12 10.6 14.98 10.6 16C10.6 17.02 10.2467 17.8833 9.54 18.59ZM5.35 17.65C5.78333 18.0767 6.32 18.29 6.96 18.29C7.6 18.29 8.13667 18.0767 8.57 17.65C9.00333 17.2167 9.22 16.6667 9.22 16C9.22 15.3333 9.00333 14.7833 8.57 14.35C8.13667 13.9167 7.6 13.7 6.96 13.7C6.32 13.7 5.78333 13.9167 5.35 14.35C4.91667 14.7833 4.7 15.3333 4.7 16C4.7 16.6667 4.91667 17.2167 5.35 17.65ZM15.7995 12.5V13.82H13.9095V19.5H12.5295V13.82H10.6495V12.5H15.7995ZM21.1999 12.5V13.82H19.3099V19.5H17.9299V13.82H16.0499V12.5H21.1999ZM27.4599 18.59C26.7533 19.29 25.8933 19.64 24.8799 19.64C23.8666 19.64 23.0066 19.29 22.2999 18.59C21.5999 17.8833 21.2499 17.02 21.2499 16C21.2499 14.98 21.5999 14.12 22.2999 13.42C23.0066 12.7133 23.8666 12.36 24.8799 12.36C25.8933 12.36 26.7533 12.7133 27.4599 13.42C28.1666 14.12 28.5199 14.98 28.5199 16C28.5199 17.02 28.1666 17.8833 27.4599 18.59ZM23.2699 17.65C23.7033 18.0767 24.2399 18.29 24.8799 18.29C25.5199 18.29 26.0566 18.0767 26.4899 17.65C26.9233 17.2167 27.1399 16.6667 27.1399 16C27.1399 15.3333 26.9233 14.7833 26.4899 14.35C26.0566 13.9167 25.5199 13.7 24.8799 13.7C24.2399 13.7 23.7033 13.9167 23.2699 14.35C22.8366 14.7833 22.6199 15.3333 22.6199 16C22.6199 16.6667 22.8366 17.2167 23.2699 17.65Z"
        fill="#F4F5F6"
      />
    </svg>
  );
}

'use client';

import { type Message as IMessage } from 'ai';

import { Message } from './Message';

export type SavedMessage = Pick<IMessage, 'id' | 'role' | 'content'> & {
  toolName?: string;
};

type SavedMessagesProps = {
  messages: SavedMessage[];
};

/**
 * SavedMessages Component
 *
 * This component is responsible for rendering saved messages.
 * It takes an array of saved messages and renders them using the Message component. For each message, it checks if the message has a toolName.
 * If it does, it parses the content as a JSON array and creates a tool invocation for each item in the array.
 * If the message does not have a toolName, it simply passes the content to the Message component.
 *
 */
export function SavedMessages({ messages }: SavedMessagesProps) {
  return messages.map((message, idx) => {
    const { content, toolName, ...rest } = message;

    return (
      <Message
        key={idx}
        message={{ ...rest, content: toolName ? '' : content }}
      />
    );
  });
}

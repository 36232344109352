import { RefObject, useEffect, useRef } from 'react';

/**
 * A custom hook that automatically scrolls to the bottom of a container when its content changes.
 *
 * @template T - The type of HTMLElement that the refs will be attached to.
 * @returns {[RefObject<T>, RefObject<T>]} An array containing two refs:
 *   - The first ref should be attached to the container element.
 *   - The second ref should be attached to an element at the bottom of the container.
 *
 * @example
 * ```tsx
 * function ChatComponent() {
 *   const [containerRef, endRef] = useScrollToBottom<HTMLDivElement>();
 *
 *   return (
 *     <div ref={containerRef}>
 *       {messages.map(message => <MessageComponent key={message.id} message={message} />)}
 *       <div ref={endRef} />
 *     </div>
 *   );
 * }
 * ```
 *
 * This hook uses a MutationObserver to detect changes in the container's content.
 * When changes occur, it smoothly scrolls to the element referenced by endRef.
 */
export function useScrollToBottom<T extends HTMLElement>(): [
  RefObject<T>,
  RefObject<T>,
] {
  const containerRef = useRef<T>(null);
  const endRef = useRef<T>(null);

  useEffect(() => {
    const container = containerRef.current;
    const end = endRef.current;

    if (container && end) {
      const observer = new MutationObserver(() => {
        end.scrollIntoView({ behavior: 'smooth' });
      });

      observer.observe(container, {
        childList: true,
        subtree: true,
      });

      return () => observer.disconnect();
    }
  }, []);

  return [containerRef, endRef];
}

'use client';

import { useMemo } from 'react';
import { LocalStorageKey } from '@/constants';
import { AIChatbotStrings } from '@/translations';
import { Typography } from '@motortrend/ids';
import { type Message as IMessage } from 'ai';
import { motion } from 'framer-motion';

import { DataId } from '@/utils/nitrous/constants';
import { useScrollToBottom } from '@/hooks/useScrollToBottom';

import { ArticlesIcon } from './icons/Articles';
import { PhotosIcon } from './icons/Photos';
import { Message } from './Message';
import { SavedMessages, type SavedMessage } from './SavedMessages';

type MessagesProps = {
  messages: IMessage[];
  append: (message: Pick<IMessage, 'content' | 'role'>) => void;
};

export function Messages({ append, messages }: MessagesProps) {
  const [messagesContainerRef, messagesEndRef] =
    useScrollToBottom<HTMLDivElement>();

  const suggestedActions = [
    { icon: ArticlesIcon, text: AIChatbotStrings.SuggestedAction1 },
    { icon: PhotosIcon, text: AIChatbotStrings.SuggestedAction2 },
    { icon: ArticlesIcon, text: AIChatbotStrings.SuggestedAction3 },
    { icon: ArticlesIcon, text: AIChatbotStrings.SuggestedAction4 },
  ];

  const savedMessages = useMemo(() => {
    if (typeof window === 'undefined') {
      return [];
    }

    const savedMessages: SavedMessage[] = JSON.parse(
      window.localStorage.getItem(LocalStorageKey.AIChatbotMessages) ?? '[]',
    );

    return savedMessages;
  }, []);

  return (
    <div
      className="flex flex-1 shrink-0 flex-col space-y-4 overflow-hidden overflow-y-auto"
      ref={messagesContainerRef}
    >
      {!messages.length && !savedMessages.length ? (
        <>
          <motion.div
            animate={{ opacity: 1, y: 0 }}
            className="w-full rounded-lg border border-solid border-neutral-6 bg-neutral-7 p-4 text-center dark:border-transparent dark:bg-neutral-2"
            exit={{ opacity: 0, y: 5 }}
            initial={{ opacity: 0, y: 5 }}
          >
            <Typography
              className="text-neutral-4 dark:text-neutral-6"
              disableColorScheme
              variant="body2"
            >
              {AIChatbotStrings.WelcomeMessage}
            </Typography>
          </motion.div>
          <motion.div
            animate={{ opacity: 1, y: 0 }}
            className="mt-4 grid gap-4 md:grid-cols-2"
            exit={{ opacity: 0, y: 5 }}
            initial={{ opacity: 0, y: 5 }}
            transition={{ delay: 0.5 }}
          >
            {suggestedActions.map((action, idx) => {
              return (
                <motion.div
                  animate={{ opacity: 1, y: 0 }}
                  className="flex w-full cursor-pointer items-center gap-2 rounded-lg border border-solid border-neutral-6 bg-neutral-7 p-6 text-center dark:border-transparent dark:bg-neutral-2"
                  data-id={DataId.AIChatbotSuggestedPrompt}
                  data-parent={DataId.AIChatbotForm}
                  initial={{ opacity: 0, y: 20 }}
                  key={idx}
                  onClick={() => {
                    append({
                      content: action.text,
                      role: 'user',
                    });
                  }}
                  transition={{ delay: 0.2 * idx }}
                >
                  <Typography
                    className="text-left text-neutral-2 dark:text-neutral-6"
                    disableColorScheme
                    variant="body3"
                  >
                    {action.text}
                  </Typography>
                  <div className="ml-auto shrink-0">
                    <action.icon />
                  </div>
                </motion.div>
              );
            })}
          </motion.div>
        </>
      ) : null}
      <SavedMessages messages={savedMessages} />
      {messages.map((message) => (
        <Message key={message.id} message={message} />
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
}
